import {
  ElementEvent,
  InputEventType,
  inputEventTypes,
  ElementType,
  Extends,
  InputElementOptions,
  Replacer,
  Matcher,
  TInputHTMLType,
  CreateSecretMessage,
  CreateSecretResponse,
  SetOptionsMessage,
  FocusMessage,
  FocusMessageResponse,
  BlurMessageResponse,
  BlurMessage,
} from '@external/shared';
import { sendMessage } from '../iframe.utils';
import { ModernbancElement } from './base.element';
import { nanoid } from 'nanoid';
import { construct, handleEvents, setOptions, subscribeToEvents, unsubscribeFromEvents } from './element.utils';

/**
 * Input element class that extends ModernbancElement
 * This class is used to manage the input element
 */
export class InputElement extends ModernbancElement<InputEventType> implements InputElementOptions {
  type: Extends<ElementType, 'input'> = 'input';
  allowed_event_types = inputEventTypes;

  'aria-label'?: string;
  default_value?: string;
  auto_complete?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  html_type?: TInputHTMLType;
  pattern?: string;
  max?: number | string;
  max_length?: number;
  min?: number | string;
  min_length?: number;
  replacers?: Replacer[];
  matchers?: Matcher[];

  deletion_date?: Date;
  variables?: Record<string, any> | null;

  constructor(api_key: string, options: InputElementOptions) {
    super();
    this.api_key = api_key;
    this.construct(options);
  }

  construct = (options: InputElementOptions) => construct(this, options);
  setOptions = (options: InputElementOptions, push = true) => setOptions(this, options, push);
  handleEvents = async (event: MessageEvent<ElementEvent<any>>) => handleEvents(this, event);
  subscribeToEvents = () => subscribeToEvents(this);
  unsubscribeFromEvents = () => unsubscribeFromEvents(this);

  /**
   * This method pushes the options to the iframe by sending a message to the iframe and handles the response
   */
  pushOptions = async () => {
    const message_id = nanoid();

    const set_options_message: SetOptionsMessage<InputElementOptions> = {
      type: 'setOptions',
      id: message_id,
      component_id: this.id,
      body: {
        id: this.id,
        type: this.type,
        html_type: this.html_type,
        class: this.class,
        'aria-label': this['aria-label'],
        auto_complete: this.auto_complete,
        disabled: this.disabled,
        placeholder: this.placeholder,
        value: this.value,
        css: this.css,
        matchers: this.matchers,
        replacers: this.replacers,
        max: this.max,
        max_length: this.max_length,
        min: this.min,
        min_length: this.min_length,
        pattern: this.pattern,
        deletion_date: this.deletion_date,
        variables: this.variables,
        google_font_url: this.google_font_url,
      },
    };
    console.log('Pushing options', set_options_message);
    const response = await sendMessage(this.html_element, set_options_message);
    console.log('Received response', response);
    return response;
  };

  /**
   * This method sends a message to the iframe to create a secret on the server and handles the response
   */
  async createSecret(): Promise<CreateSecretResponse> {
    const message_id = nanoid();
    console.log('Creating Secret', message_id);
    try {
      const message: CreateSecretMessage = {
        id: message_id,
        component_id: this.id,
        type: 'createSecret',
      };
      const response = await sendMessage<'createSecret', CreateSecretResponse>(this.html_element, message);
      return response;
    } catch (err) {
      const error = err as any;
      return {
        id: message_id,
        component_id: this.id,
        type: 'createSecret',
        is_error: true,
        error: {
          code: 'UNKNOWN',
          message: error.toString(),
        },
      };
    }
  }

  /**
   * This method sends a message to the iframe to focus the input element
   */
  async focus(): Promise<FocusMessageResponse> {
    const message_id = nanoid();
    console.log('Focusing', message_id);
    try {
      const message: FocusMessage = {
        id: message_id,
        component_id: this.id,
        type: 'focus',
      };
      const response = await sendMessage<'focus', FocusMessageResponse>(this.html_element, message);
      return response;
    } catch (err) {
      const error = err as any;
      return {
        id: message_id,
        component_id: this.id,
        type: 'focus',
        is_error: true,
        error: `Error focusing, ${error.toString()}`,
      };
    }
  }

  /**
   * This method sends a message to the iframe to blur the input element
   */
  async blur(): Promise<BlurMessageResponse> {
    const message_id = nanoid();
    console.log('Blurring', message_id);
    const message: BlurMessage = {
      id: message_id,
      component_id: this.id,
      type: 'blur',
    };
    try {
      const response = await sendMessage<'blur', BlurMessageResponse>(this.html_element, message);
      return response;
    } catch (err) {
      const error = err as any;
      return {
        id: message_id,
        component_id: this.id,
        type: 'blur',
        is_error: true,
        error: `Error blurring, ${error.toString()}`,
      };
    }
  }
}
