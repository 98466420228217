import { elementTypes, AddInputElementOptions, AddOutputElementOptions, ElementType } from '@external/shared';
import { InputElement } from './element/input.element';
import { OutputElement } from './element/output.element';

/**
 * This is the main class for the Modernbanc SDK.
 * It is used to create and manage Modernbanc Elements.
 */
export class Modernbanc {
  element_map = new Map<string, InputElement | OutputElement>();

  constructor(private readonly api_key: string) {}

  static init(api_key: string) {
    const modernbanc = new Modernbanc(api_key);
    return modernbanc;
  }

  /**
   * Method to get an element by its type and id
   */
  getElement(type: 'input', id: string): InputElement | undefined;
  getElement(type: 'output', id: string): OutputElement | undefined;
  getElement(type: ElementType, id: string) {
    const element = this.element_map.get(id);
    return element?.type === type ? element : undefined;
  }

  /**
   * Method to add an element to the element map and also mount it to the DOM if selectors are provided
   */
  addElement(options: AddInputElementOptions): InputElement;
  addElement(options: AddOutputElementOptions): OutputElement;
  addElement(options: AddInputElementOptions | AddOutputElementOptions): InputElement | OutputElement {
    const { selectors, ...element_options } = options;
    let element: InputElement | OutputElement;

    if (!element_options.type || !elementTypes.includes(element_options.type)) {
      throw new Error(`Unknown element type ${element_options.type}`);
    }

    const existing_element = this.element_map.get(element_options.id);
    if (existing_element) {
      // Maybe show a warning here but I don't want to spam our when user is just using StrictNode.
      return existing_element;
    }

    if (element_options.type === 'input') {
      element = new InputElement(this.api_key, element_options);
    } else if (element_options.type === 'output') {
      element = new OutputElement(this.api_key, element_options);
    }

    if (selectors) {
      element!.mount(selectors);
    }

    this.element_map.set(element!.id, element!);
    return element!;
  }
}
