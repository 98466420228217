import Modernbanc from '@external/mdb-js';
import { createContext, useContext, useState } from 'react';

type TModernbancContext = {
  modernbanc: Modernbanc;
};

export const ModernbancContext = createContext<TModernbancContext>({} as TModernbancContext);

/**
 * Provider to provide the Modernbanc instance to the children
 */
export const ModernbancProvider = ({ children, api_key }: React.PropsWithChildren<{ api_key: string }>) => {
  const [modernbanc] = useState(new Modernbanc(api_key));
  return <ModernbancContext.Provider value={{ modernbanc }}>{children}</ModernbancContext.Provider>;
};

/**
 * Hook to get the Modernbanc instance from the context
 */
export function useModernbancContext() {
  return useContext(ModernbancContext);
}
