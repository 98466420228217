import styled, { css, keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  justify-content: center;
  border: none;
  flex-direction: column;
  margin-top: 30px;
  /* background: #202020; */

  button {
    box-shadow: none;
    outline: none;
    border: none;
  }
`;

const Form = styled.div`
  display: flex;
  position: relative;
  border-radius: 8px;
  flex-direction: column;
  margin: 20px;
  gap: 10px;
  width: 400px;
  background: #fafafa;
  /* background: #202020; */

  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.14);
`;

const Label = styled.div`
  padding: 8px;
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 20px;
  align-items: center;
`;
export const Header = styled.div`
  display: flex;
  padding: 14px 20px;
  align-items: center;
  gap: 5px;

  color: #a1a1aa;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.13px;
  border-bottom: 1px solid #ebebeb;
`;

export const Chevron = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6051 10.2949C5.47387 10.1636 5.40015 9.98562 5.40015 9.8C5.40015 9.61439 5.47387 9.43637 5.6051 9.3051L7.9102 7L5.6051 4.6949C5.47759 4.56288 5.40703 4.38606 5.40862 4.20252C5.41022 4.01899 5.48384 3.84342 5.61362 3.71363C5.74341 3.58385 5.91898 3.51023 6.10252 3.50863C6.28605 3.50704 6.46288 3.57759 6.5949 3.7051L9.3949 6.5051C9.52613 6.63637 9.59985 6.81439 9.59985 7C9.59985 7.18562 9.52613 7.36363 9.3949 7.4949L6.5949 10.2949C6.46363 10.4261 6.28561 10.4999 6.1 10.4999C5.91438 10.4999 5.73637 10.4261 5.6051 10.2949Z"
      fill="#71717A"
    />
  </svg>
);

type TProps = { success?: boolean; loading: boolean; error?: boolean };
export const ResultComponent = ({ success, loading, error }: TProps) => {
  if (error) {
    return (
      <ResultContainer style={{ color: 'red', justifyContent: 'center' }}>
        Something went wrong. Please try again later
      </ResultContainer>
    );
  }

  if (success === undefined && !loading) return null;
  if (success === undefined) {
    return (
      <ResultContainer>
        <Spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.83267 1.09741C7.09176 1.05891 7.35561 1.1236 7.56751 1.27758C7.77941 1.43156 7.92243 1.66254 7.96584 1.92086C8.00924 2.17917 7.94955 2.4442 7.79961 2.65898C7.64967 2.87376 7.42145 3.02113 7.164 3.06941C5.99943 3.26718 4.94244 3.87077 4.18029 4.77324C3.41814 5.67572 3.00002 6.81883 3 8.00008C3 9.32616 3.52678 10.5979 4.46447 11.5356C5.40215 12.4733 6.67392 13.0001 8 13.0001V15.0001C4.134 15.0001 1 11.8667 1 8.00008C1 4.57008 3.484 1.66008 6.83267 1.09741Z"
              fill="url(#paint0_linear_26388_4536)"
            />
            <path
              d="M11.2613 2.86537C11.349 2.76753 11.4551 2.68794 11.5736 2.63113C11.6921 2.57433 11.8206 2.54143 11.9518 2.53431C12.083 2.52719 12.2143 2.54599 12.3382 2.58964C12.4621 2.63329 12.5762 2.70093 12.674 2.7887C13.4068 3.44459 13.9928 4.24786 14.3936 5.14592C14.7945 6.04398 15.0011 7.01658 15 8.00004C15 11.8667 11.866 15 8 15V13C9.01211 13.0001 10.0004 12.6929 10.8342 12.1192C11.668 11.5455 12.3081 10.7323 12.6698 9.78706C13.0316 8.8418 13.0979 7.80902 12.8601 6.82525C12.6223 5.84148 12.0915 4.95304 11.338 4.27737C11.1406 4.10028 11.0216 3.85204 11.0073 3.58724C10.9929 3.32245 11.0843 3.06279 11.2613 2.86537Z"
              fill="url(#paint1_linear_26388_4536)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_26388_4536"
                x1="351"
                y1="74.4248"
                x2="351"
                y2="1278.25"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="1" stopOpacity="0.55" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_26388_4536"
                x1="358"
                y1="192.533"
                x2="358"
                y2="1089.05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0" />
                <stop offset="1" stopOpacity="0.55" />
              </linearGradient>
            </defs>
          </svg>
        </Spinner>
        Loading
      </ResultContainer>
    );
  }
  return (
    <ResultContainer>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <rect width="20" height="20" rx="10" fill="#BFFFB9" />
        <path
          d="M8.34884 12.4486L5.97818 10.078L5.1709 10.8795L8.34884 14.0575L15.1709 7.23543L14.3693 6.43384L8.34884 12.4486Z"
          fill="black"
        />
      </svg>
      Success
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #000;
  text-align: center;
  font-size: 13px;
  /* font-weight: 500; */
  letter-spacing: 0.13px;
`;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.svg`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spin} 1s linear infinite;
`;

/** This one is just for logo */
const CardInputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const input_style = css<HTMLInputElement>`
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  width: 100%;
  padding: 0 8px;
  font-size: 13px;
  outline: none;
  transition: all ease-in-out 125ms;
  font-family: 'Inter', sans-serif;

  &.valid {
    background: red;
  }

  :focus {
    border: 1px solid #6e79d6;
    box-shadow: 0px 0px 0px 3px #eef2ff;
  }

  &.valid_card_number {
    border: 1px solid green;
  }

  &.valid_cvc {
    border: 1px solid green;
  }

  &.valid_expiry_date {
    border: 1px solid green;
  }
`;

export const output_style = css`
  background: #f0f0f0;
  color: black;
  border: 1px solid #aaaaaa;
  line-height: 100%;
  border-radius: 4px;
  padding: 8px;
  font-size: 13px;
  outline: none;
  font-family: 'Inter', sans-serif;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &[data-show='false'] {
    display: none;
  }
`;

const Button = styled.button`
  margin: 5px 20px 20px 20px;
  background: black;
  font-size: 13px;

  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  font-family: 'Inter';

  :hover {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const OutputBlock = styled.div`
  margin-top: 20px;
  padding: 15px;
  background: #f0f0f0;
  border: 1px solid #ebebeb;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;

  & > button {
    margin: 0;
  }
`;

const ElementContainer = styled.div`
  height: 32px;
  width: 100%;
`;

const SecretInput = styled.input`
  height: 32px;
  border: 1px solid #e2e2e2;
  padding: 0 5px;
  color: black;
  background: #fafafa;
  border-radius: 4px;
  width: 100%;
`;

export default {
  Container,
  ButtonContainer,
  Form,
  Label,
  Button,
  OutputBlock,
  input_css: input_style,
  output_style,
  CardInputWrapper,
  ElementContainer,
  SecretInput,
};
