type TProps = {
  width?: string;
  height?: string;
};
export const MastercardIcon = ({ width = '31', height = '14' }: TProps) => (
  <svg width={width} height={height} viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.133057" width="30" height="18" rx="3.42857" fill="black" />
    <g clipPath="url(#clip0_27046_4871)">
      <path d="M12.46 4.2359H17.7851V13.8043H12.46V4.2359Z" fill="#FF5F00" />
      <path
        d="M12.7986 9.02002C12.7986 7.07593 13.7114 5.35155 15.1145 4.23576C14.0834 3.42432 12.7817 2.93408 11.3617 2.93408C7.99736 2.93408 5.27563 5.65581 5.27563 9.02002C5.27563 12.3842 7.99736 15.106 11.3616 15.106C12.7816 15.106 14.0833 14.6157 15.1145 13.8042C13.7114 12.7054 12.7986 10.9641 12.7986 9.02002Z"
        fill="#EB001B"
      />
      <path
        d="M24.9702 9.02002C24.9702 12.3842 22.2485 15.106 18.8843 15.106C17.4643 15.106 16.1626 14.6157 15.1313 13.8042C16.5514 12.6885 17.4474 10.9641 17.4474 9.02002C17.4474 7.07593 16.5345 5.35155 15.1313 4.23576C16.1625 3.42432 17.4643 2.93408 18.8843 2.93408C22.2485 2.93408 24.9702 5.67275 24.9702 9.02002Z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_27046_4871">
        <rect width="19.7143" height="12.1319" fill="white" transform="translate(5.27563 2.93408)" />
      </clipPath>
    </defs>
  </svg>
);
