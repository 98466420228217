import { baseEventTypes, BaseElementOptions, ElementType } from '@external/shared';

/*
 * This is the base class for all Modernbanc Elements.
 * It provides basic structure for all elements, and the ability to register event handlers.
 * It also provides the mount and unmount methods, which are used to add or remove the element from the DOM.
 */
export abstract class ModernbancElement<EventType extends string> implements BaseElementOptions {
  type: ElementType;
  id: string;
  iframe_id: string;
  api_key: string;
  class?: string;
  css?: string;
  google_font_url?: string;

  /**
   * This is a map of event types to their respective handler functions.
   */
  registered_event_handlers = new Map<EventType, Function>();

  allowed_event_types: readonly EventType[] = baseEventTypes as any;

  /**
   * This is the HTML element(iFrame) that will be added to the DOM for the element.
   */
  public html_element: HTMLIFrameElement;

  /**
   * Register an event handler function for the given event type.
   * @param event_type
   * @param handler_fn
   */
  on = <T extends EventType>(event_type: T, handler_fn?: Function) => {
    if (handler_fn) {
      this.registered_event_handlers.set(event_type, handler_fn);
    } else {
      this.registered_event_handlers.delete(event_type);
    }
  };

  /**
   * This method mounts/adds the element to the DOM.
   * @param selectors
   */
  mount = (selectors: string) => {
    const parent_element = document.querySelector(selectors);
    if (!parent_element) return;

    const existing_frame = document.getElementById(this.iframe_id);
    this.html_element.id = this.iframe_id;
    if (existing_frame) {
      // iFrame already exists, you might update it or simply leave it as is
      return;
    }

    parent_element?.append(this.html_element);
  };

  /**
   * This method unmounts/removes the element from the DOM.
   * @param element
   */
  unmount = (element: ModernbancElement<any>) => {
    element.html_element.remove();
  };
}
