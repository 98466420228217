/** Input events sent from iFrame to parent */

import { TMessageMetadata } from './types';

/* Base */
export const baseEventTypes = ['ready'] as const;
export type BaseEventType = typeof baseEventTypes[number];

export const inputEventTypes = [...baseEventTypes, 'change', 'blur', 'focus', 'key_down'] as const;
export type InputEventType = typeof inputEventTypes[number];

export type ElementEvent<T = BaseEventType, D = void> = TMessageMetadata &
  (D extends void
    ? {
        type: T;
      }
    : {
        type: T;
        data: D;
      });

export type InputChangeEventData = {
  /** Whether input is empty */
  empty: boolean;

  /** Whether the input was touched - useful for replicating form behaviours*/
  touched: boolean;

  /**
   * Match results where key is Matcher name and boolean is whether it matched.
   */
  matches?: Matches;

  /** Deprecated - remove after customers migrated to react 0.0.10 */
  valid?: boolean;
};

export type Matches = Omit<Record<string, boolean>, 'card_type'> & {
  card_type?: string;
};

export type InputKeydownEventData = {
  key: string;
} & Pick<KeyboardEvent, 'altKey' | 'ctrlKey' | 'shiftKey' | 'metaKey'>;

export type BaseElementEvent<T extends BaseEventType> = T extends 'ready' ? ElementEvent<T> : never;

export type InputElementEvent<T extends InputEventType> =
  | (T extends 'ready' ? ElementEvent<T> : never)
  | (T extends 'focus' ? ElementEvent<T> : never)
  | (T extends 'blur' ? ElementEvent<T> : never)
  | (T extends 'change' ? ElementEvent<T, InputChangeEventData> : never)
  | (T extends 'key_down' ? ElementEvent<T, InputKeydownEventData> : never);
