import { VisaIcon } from './visa-icon.svg';
import { AmexIcon } from './amex-icon.svg';
import { MastercardIcon } from './mastercard-icon.svg';
import { OtherCardIcon } from './other-card-icon.svg';
import styled from 'styled-components';

type TProps = {
  width?: string;
  height?: string;
  type: 'visa' | 'american-express' | 'mastercard' | 'other';
};

export const CardLogo = (props: TProps) => (
  <Container>
    <LogoWrapper data-visible={props.type === 'visa'}>
      <VisaIcon {...props} />
    </LogoWrapper>
    <LogoWrapper data-visible={props.type === 'american-express'}>
      <AmexIcon {...props} />
    </LogoWrapper>
    <LogoWrapper data-visible={props.type === 'mastercard'}>
      <MastercardIcon {...props} />
    </LogoWrapper>
    <LogoWrapper data-visible={props.type === 'other'}>
      <OtherCardIcon {...props} />
    </LogoWrapper>
  </Container>
);

const Container = styled.div`
  width: 31px;

  /** Important as this icon would float above your input */
  position: absolute;

  /** How far is it from the right edge of the input. */
  right: 4px;

  /** Make his height equal to your input height. */
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 31px;
  height: 18px;
  display: flex;
  align-items: center;
  transition: opacity ease-in-out 100ms;

  &[data-visible='true'] {
    opacity: 1;
    width: max-content;
  }

  &[data-visible='false'] {
    opacity: 0;
    width: 0;
  }
`;

export const CardLogoIcon = ({ type, width, height }: TProps) => {
  if (type === 'visa') return <VisaIcon width={width} height={height} />;
  if (type === 'american-express') return <AmexIcon width={width} height={height} />;
  if (type === 'mastercard') return <MastercardIcon width={width} height={height} />;
  return <OtherCardIcon width={width} height={height} />;
};
