import { OutputElement, useModernbancContext } from '../../lib';
import { useState } from 'react';
import { output_style } from '../form/form.styles';
import S, { Chevron, Header, Main, ResultComponent, Row } from '../form/form.styles';

/**
 * Docs example - Output element
 */
const View = () => {
  const context = useModernbancContext();
  const [secret_id, setSecretId] = useState<string>('SECRET_FEB24_DNkteVXb397PLimvfI8');
  const [loading, setLoading] = useState(true);
  const element = context.modernbanc.getElement('output', 'number');
  const [reveal, setReveal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  async function show() {
    setLoading(true);
    const response = await element?.showSecretValue();
    if (response?.is_error) {
      console.error(response.error);
      setLoading(false);
      setError(true);
      return;
    }

    // Component has now revealed the secret value in your app.
    setLoading(false);
    setReveal(true);
  }

  return (
    <S.Container>
      <S.Label>Reveal Data</S.Label>
      <S.Form>
        <Header>
          Check your card details <Chevron />
        </Header>

        <Main data-show={!loading && !error}>
          <Row>
            <S.SecretInput
              spellCheck={false}
              placeholder={'Enter secret id to reveal'}
              type="text"
              value={secret_id}
              onChange={(e) => {
                setSecretId(e.target.value);
                element?.setOptions({ type: 'output', id: 'number', secret_id: e.target.value });
              }}
            />
          </Row>
          <Row>
            <OutputElement
              css={output_style.toString()}
              id="number"
              google_font_url="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"
              container={{ style: { height: '28px', width: '100%' } }}
              secret_id={secret_id}
              onReady={() => {
                setLoading(false);
              }}
              placeholder="**** **** **** ****"
            />
          </Row>
          {!reveal ? (
            <S.Button disabled={!secret_id} onClick={show}>
              Show
            </S.Button>
          ) : (
            <span style={{ marginBottom: '20px' }} />
          )}
        </Main>
        <ResultComponent loading={loading} error={error} />
      </S.Form>
    </S.Container>
  );
};

export default View;
