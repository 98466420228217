import { ModernbancProvider } from '../lib/provider';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Output from './docs-examples/output';
// import ReactDocPage from './docs-examples/view';
import Form from './form/form.comp';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import { API_KEY } from './constants';
import './app.css';
import { useLocation } from 'react-router-dom';

const App = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const example = query.get('example');

  return (
    <ModernbancProvider api_key={API_KEY}>
      <Container>
        {example === 'input' && <Form />}
        {example === 'output' && <Output />}
        {!example && (
          <Tabs className={'TabsRoot'}>
            <TabList className={'TabsList'}>
              <Tab className={'TabsTrigger'}>Input Example</Tab>
              <Tab className={'TabsTrigger'}>Output Example</Tab>
              {/*<Tab>Mdb React Docs Page Example</Tab>*/}
            </TabList>

            <TabPanel>
              <Form />
            </TabPanel>
            <TabPanel>
              <Output />
            </TabPanel>
            {/*<TabPanel>*/}
            {/*  <ReactDocPage />*/}
            {/*</TabPanel>*/}
          </Tabs>
        )}
      </Container>
    </ModernbancProvider>
  );
};

export default App;

const Container = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  width: 400px;
  margin: 5% auto 0 auto;
  font-family: 'Inter', sans-serif;

  input:focus-visible {
    outline: none;
    border: 1px solid #6e79d6 !important;
    box-shadow: 0px 0px 0px 3px #eef2ff;
  }
`;
