import { InputElement, useModernbancContext, InputElementRef } from '../../lib';
import { useRef, useState } from 'react';
import S, { Chevron, Header, Main, ResultComponent, Row } from './form.styles';
import { CardLogo } from './card-logo/card-logo.component';

const font = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap';
const number_likely_visa_regex = /^4(\d|\s){0,15}$/;
const number_likely_mastercard_regex =
  /^(5[1-5](\d|\s){0,14}|222[1-9](\d|\s){0,11}|22[3-9](\d|\s){0,12}|2[3-6](\d|\s){0,13}|27[01](\d|\s){0,12}|2720(\d|\s){0,11})$/;
const number_likely_amex_regex = /^3[47](\d|\s){0,13}$/;

type TCardType = 'visa' | 'mastercard' | 'american-express' | 'other';
const View = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();
  const css = S.input_css.toString();
  // const output_css = S.output_style.toString();
  const card_number_ref = useRef<InputElementRef>(null);
  const expiry_date_ref = useRef<InputElementRef>(null);
  const cvc_ref = useRef<InputElementRef>(null);
  const [valid_card_number, setValidCardNumber] = useState<boolean>(false);
  const [valid_expiry_date, setValidExpiryDate] = useState<boolean>(false);
  const [valid_cvc, setValidCvc] = useState<boolean>(false);

  const deletion_date = new Date();
  deletion_date.setDate(deletion_date.getDate() + 1);

  const context = useModernbancContext();

  const [number_input_is_ready, setNumberInputIsReady] = useState(false);
  const [expiry_date_input_is_ready, setExpiryDateInputIsReady] = useState(false);
  const [cvc_input_is_ready, setCvcInputIsReady] = useState(false);
  const all_inputs_are_ready = number_input_is_ready && expiry_date_input_is_ready && cvc_input_is_ready;
  const [card_type, setCardType] = useState<'visa' | 'mastercard' | 'american-express' | 'other'>('other');

  const show_form = all_inputs_are_ready && success === undefined && !loading;
  const can_submit = valid_card_number && valid_expiry_date && valid_cvc;

  async function submit() {
    setLoading(true);

    // This will create token from each input's value.
    const number_promise = context.modernbanc.getElement('input', 'number')?.createSecret();
    const exp_date_promise = context.modernbanc.getElement('input', 'exp-date')?.createSecret();
    const cvc_promise = context.modernbanc.getElement('input', 'cvc')?.createSecret();

    const promises = [number_promise, exp_date_promise, cvc_promise];
    const [number_result, exp_date_result, cvc_result] = await Promise.all(promises);
    console.log('Backend result', number_result);

    if (number_result?.is_error || cvc_result?.is_error || exp_date_result?.is_error) {
      console.log('Backend error', number_result, exp_date_result, cvc_result);
      setSuccess(false);
    }

    setSuccess(true);
    setLoading(false);
  }

  return (
    <S.Container>
      <S.Label>Collecting card information</S.Label>
      <S.Form>
        <Header>
          Enter your card details <Chevron />
        </Header>

        <Main data-show={show_form}>
          <Row>
            <S.CardInputWrapper>
              <InputElement
                ref={card_number_ref}
                id="number"
                placeholder="0000 0000 0000 0000"
                container={{ style: { height: '28px', width: '100%' } }}
                google_font_url={font}
                css={css}
                onChange={({ data }) => {
                  const valid_card_number = data.matches?.valid_card_number;
                  setValidCardNumber(!!valid_card_number);
                  const card_type = data.matches?.card_type;
                  if (card_type) {
                    const card_types = ['visa', 'mastercard', 'american-express'];
                    const type = card_types.includes(card_type) ? card_type : 'other';
                    setCardType(type as TCardType);
                  } else {
                    // Determine the card type based on the likely prefix match
                    let type: TCardType = 'other'; // Default to 'other'
                    if (data.matches?.likely_visa) {
                      type = 'visa';
                    } else if (data.matches?.likely_mastercard) {
                      type = 'mastercard';
                    } else if (data.matches?.likely_american_express) {
                      type = 'american-express';
                    }
                    setCardType(type);
                  }
                }}
                replacers={[{ type: 'card_number' }]}
                matchers={[
                  { type: 'valid_card_number' },
                  { type: 'card_type' },
                  {
                    type: 'regex',
                    pattern: number_likely_visa_regex.source, // Matches Visa starting with 4, up to one less than the minimum length
                    name: 'likely_visa',
                  },
                  {
                    type: 'regex',
                    pattern: number_likely_mastercard_regex.source, // Matches Mastercard, up to one less than the full length
                    name: 'likely_mastercard',
                  },
                  {
                    type: 'regex',
                    pattern: number_likely_amex_regex.source, // Matches American Express, up to one less than the full length
                    name: 'likely_american_express',
                  },
                ]}
                onReady={() => {
                  setNumberInputIsReady(true);
                }}
                deletion_date={deletion_date}
                variables={{ test: 'example' }}
              />
              <CardLogo type={card_type} />
            </S.CardInputWrapper>

            <InputElement
              ref={expiry_date_ref}
              id="exp-date"
              placeholder="MM/YY"
              container={{ style: { height: '28px', width: '120px' } }}
              replacers={[{ type: 'expiry_date' }]}
              matchers={[{ type: 'valid_expiry_date' }]}
              onChange={(data) => {
                setValidExpiryDate(!!data.data.matches?.['valid_expiry_date']);
              }}
              css={css}
              google_font_url={font}
              onReady={() => setExpiryDateInputIsReady(true)}
              deletion_date={deletion_date}
            />
            <InputElement
              ref={cvc_ref}
              id="cvc"
              placeholder="CVC"
              container={{ style: { height: '28px', width: '85px' } }}
              css={css}
              onReady={() => setCvcInputIsReady(true)}
              matchers={[{ type: 'regex', pattern: '^[0-9]{3,4}$', name: 'valid_cvc' }]}
              onChange={(data) => {
                setValidCvc(!!data.data.matches?.['valid_cvc']);
              }}
              google_font_url={font}
              deletion_date={deletion_date}
            />
          </Row>
          <S.Button disabled={!can_submit} onClick={submit}>
            Submit
          </S.Button>
        </Main>
        <ResultComponent loading={loading || !all_inputs_are_ready} success={success} />

        {/* <S.ButtonContainer> */}
        {/*   <S.Button onClick={() => card_number_ref.current?.focus()}>Focus Card number</S.Button> */}
        {/*   <S.Button onClick={() => card_number_ref.current?.blur()}>Blur Card number</S.Button> */}
        {/*   <S.Button onClick={() => expiry_date_ref.current?.focus()}>Focus Expiry</S.Button> */}
        {/*   <S.Button onClick={() => expiry_date_ref.current?.blur()}>Blur Expiry</S.Button> */}
        {/*   <S.Button onClick={() => cvc_ref.current?.focus()}>Focus Cvc</S.Button> */}
        {/*   <S.Button onClick={() => cvc_ref.current?.blur()}>Blur Cvc</S.Button> */}
        {/*   <S.Button */}
        {/*     onClick={() => { */}
        {/*       let currentFocus = 0; */}
        {/*       const inputs = [card_number_ref, expiry_date_ref, cvc_ref]; */}
        {/**/}
        {/*       const intervalId = setInterval(() => { */}
        {/*         inputs[currentFocus].current?.focus(); */}
        {/*         currentFocus = (currentFocus + 1) % inputs.length; */}
        {/*       }, 100); */}
        {/**/}
        {/*       setTimeout(() => { */}
        {/*         clearInterval(intervalId); */}
        {/*       }, 10000); // Clear the interval after 10 seconds */}
        {/*     }} */}
        {/*   > */}
        {/*     Auto Focus */}
        {/*   </S.Button> */}
        {/* </S.ButtonContainer> */}
      </S.Form>
    </S.Container>
  );
};

export default View;
