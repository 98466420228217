type TProps = {
  width?: string;
  height?: string;
};
export const OtherCardIcon = ({ width = '24', height = '12' }: TProps) => (
  <svg width={width} height={height} viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g className="layer">
      <title>Layer 1</title>
      <path
        d="m5.6,14.69l0,0l0,-7.73l24.73,0l0,7.73c0,1.07 -0.29,1.83 -0.84,2.33c-0.54,0.5 -1.38,0.77 -2.55,0.77l-17.96,0c-1.16,0 -2,-0.27 -2.55,-0.77c-0.54,-0.5 -0.83,-1.26 -0.83,-2.33zm24.73,-11.37l0,0.78l-24.73,0l0,-0.78c0,0 0,0 0,0c0,0 0,0 0,0c0,-1.07 0.29,-1.83 0.83,-2.33c0.55,-0.5 1.39,-0.78 2.55,-0.78l17.96,0c1.17,0 2.01,0.28 2.55,0.78c0.55,0.5 0.84,1.26 0.84,2.33zm-21.33,6.78l0,1.98c0,0.36 0.13,0.68 0.38,0.9c0.24,0.23 0.57,0.34 0.96,0.34l2.8,0c0.38,0 0.72,-0.11 0.96,-0.34c0.25,-0.22 0.38,-0.54 0.38,-0.9l0,-1.98c0,-0.35 -0.13,-0.67 -0.38,-0.89c-0.24,-0.23 -0.57,-0.35 -0.96,-0.35l-2.8,0c-0.39,0 -0.72,0.12 -0.96,0.35c-0.25,0.22 -0.38,0.54 -0.38,0.89z"
        fill="#C2C2C2"
        id="svg_1"
        stroke="#BDBDBD"
      />
    </g>
  </svg>
);
