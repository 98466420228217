type TProps = {
  width?: string;
  height?: string;
};
export const AmexIcon = ({ width = '31', height = '14' }: TProps) => (
  <svg width={width} height={height} viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-0.00976562 16.0321C-0.00976562 17.0942 0.847735 17.9559 1.90466 17.9559L28.0758 18C29.1327 18 29.9902 17.1383 29.9902 16.0761V16.0321H27.5294L26.2571 14.6252L24.9848 16.0321H16.8605V9.46692H14.2361L17.4946 2.06411H20.6295L21.7502 4.59717V2.06411H25.6269L26.301 3.97192L26.979 2.06411H29.9902V1.96792C29.9902 0.905794 29.1327 0.0440673 28.0758 0.0440673L1.90466 0C0.847735 0 -0.00976562 0.861725 -0.00976562 1.92385V16.0321ZM24.582 15.2064L26.2651 13.3507L27.9402 15.2064H29.9902L27.2781 12.3166L29.9902 9.42684H27.9721L26.297 11.2986L24.6418 9.42684H22.5918L25.2839 12.3327L22.5958 15.2024V13.8757H19.2854V12.986H22.512V11.6914H19.2854V10.7936H22.5958V9.46692H17.7299V15.2064H24.582ZM28.4228 12.3206L29.9902 14V10.6613L28.4228 12.3206ZM26.975 8.63325L28.4467 4.60118V8.63325H29.9902V2.88976H27.5892L26.305 6.46892L25.0327 2.88976H22.5918V8.62524L20.0711 2.88976H18.0291L15.5403 8.63325H17.2553L17.7299 7.48295H20.3583L20.8369 8.63325H24.1353V4.61321L25.603 8.63325H26.975ZM18.2644 6.19237L19.0421 4.3126L19.8159 6.19237H18.2644Z"
      fill="#1E54DF"
    />
  </svg>
);
