import App from './app';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const AppWithRouter = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

createRoot(document.getElementById('root')!).render(<AppWithRouter />);
