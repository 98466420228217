import { Modernbanc } from './mdb';

declare global {
  interface Window {
    // @ts-ignore
    Modernbanc: typeof Modernbanc;
  }
}

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.Modernbanc = Modernbanc;
}

/* So that they appear in dist/index.d.ts file */
export default Modernbanc;
export * from './types';
