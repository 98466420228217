type TProps = {
  width?: string;
  height?: string;
};
export const VisaIcon = ({ width = '26', height = '18' }: TProps) => (
  <svg width={width} height={height} viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.490165" y="0.214286" width="29.5714" height="17.5714" rx="3.21429" fill="#F0F0F0" />
    <rect
      x="0.490165"
      y="0.214286"
      width="29.5714"
      height="17.5714"
      rx="3.21429"
      stroke="#E2E2E2"
      strokeWidth="0.428571"
    />
    <path
      d="M20.3335 5.86496C19.8155 5.67624 19.2681 5.58154 18.7169 5.58531C16.9343 5.58531 15.6753 6.48329 15.6664 7.77233C15.6553 8.71933 16.561 9.25299 17.2462 9.5694C17.9492 9.89472 18.1843 10.0997 18.1821 10.3905C18.1776 10.8328 17.6206 11.0334 17.1025 11.0334C16.3906 11.0334 16.0018 10.9342 15.4035 10.6857L15.1818 10.5821L14.9289 12.0661C15.3623 12.2466 16.1433 12.4015 16.9499 12.4149C18.845 12.4149 20.0839 11.5225 20.0984 10.151C20.114 9.39448 19.6238 8.82294 18.5921 8.35056C17.9648 8.04417 17.576 7.83918 17.576 7.52834C17.576 7.25315 17.9091 6.95902 18.6055 6.95902C19.0689 6.94781 19.5294 7.03476 19.9569 7.21416L20.124 7.2888L20.3769 5.85493L20.3335 5.86496ZM24.9593 5.70564H23.5666C23.1332 5.70564 22.8068 5.82485 22.6174 6.2549L19.9391 12.3224H21.8342L22.213 11.3275L24.5259 11.3297C24.5805 11.5626 24.7476 12.3224 24.7476 12.3224H26.4188L24.9593 5.70564ZM13.1028 5.64993H14.9088L13.7791 12.27H11.9753L13.1028 5.6477V5.64993ZM8.51823 9.29867L8.7054 10.2178L10.4702 5.70564H12.3831L9.54099 12.3135H7.6325L6.07274 6.71837C6.04747 6.62611 5.98815 6.54688 5.90673 6.49666C5.34455 6.20625 4.74888 5.98587 4.13306 5.84045L4.15757 5.70118H7.0643C7.4587 5.71678 7.77622 5.84045 7.88207 6.26158L8.51711 9.30201L8.51823 9.29867ZM22.731 9.97383L23.4508 8.12216C23.4418 8.14222 23.5989 7.74002 23.6903 7.49157L23.814 8.06311L24.2318 9.97271H22.7299L22.731 9.97383Z"
      fill="#001BA9"
    />
  </svg>
);
