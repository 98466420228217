import { OutputElementOptions } from '@external/shared';
import { OutputElement as HTMLOutputElement, BaseElementEvent } from '@external/mdb-js';
import { CSSProperties, useEffect, useState } from 'react';
import { useModernbancContext } from './provider';
import { usePrevious } from './utils';

type OutputElementProps = Omit<OutputElementOptions, 'type'> & {
  onReady?: (event: BaseElementEvent<'ready'>) => void;
  container?: {
    ref?: React.RefObject<HTMLDivElement>;
    style?: CSSProperties;
  };
};

/**
 * Output element component in React to manage the Modernbanc output element
 */
export const OutputElement = ({ container, onReady, ...other_options }: OutputElementProps) => {
  const context = useModernbancContext();
  const [element, setElement] = useState<HTMLOutputElement>();
  const previous_id = usePrevious(other_options.id);

  useEffect(() => {
    setElement(context.modernbanc.addElement({ type: 'output', ...other_options, selectors: `#${other_options.id}` }));
  }, []);

  useEffect(() => {
    element?.setOptions({ type: 'output', ...other_options });
  }, [other_options]);

  useEffect(() => {
    element?.on('ready', onReady);
  }, [element, onReady]);

  useEffect(() => {
    /* Update element id in element map */
    if (previous_id) {
      const element = context.modernbanc.element_map.get(previous_id);
      if (element) {
        context.modernbanc.element_map.set(other_options.id, element);
        context.modernbanc.element_map.delete(previous_id);
      }
    }
    return () => {
      context.modernbanc.element_map.delete(other_options.id);
    };
  }, [other_options.id]);

  return <div id={other_options.id} {...container} />;
};
