import {
  BaseEventType,
  ElementEvent,
  baseEventTypes,
  ElementType,
  Extends,
  OutputElementOptions,
  SetOptionsMessage,
  ShowSecretValueMessage,
  ShowSecretValueResponse,
} from '@external/shared';
import { nanoid } from 'nanoid';
import { sendMessage } from '../iframe.utils';
import { ModernbancElement } from './base.element';
import { construct, handleEvents, setOptions, subscribeToEvents, unsubscribeFromEvents } from './element.utils';

/**
 * Output element class that extends ModernbancElement
 * This class is used to manage the output element
 */
export class OutputElement extends ModernbancElement<BaseEventType> implements OutputElementOptions {
  type: Extends<ElementType, 'output'> = 'output';
  allowed_event_types = baseEventTypes;
  secret_id: string;
  placeholder?: string;

  constructor(api_key: string, options: OutputElementOptions) {
    super();
    this.api_key = api_key;
    construct(this, options);
  }

  construct = (options: OutputElementOptions) => construct(this, options);
  setOptions = (options: OutputElementOptions, push = true) => setOptions(this, options, push);
  handleEvents = async (event: MessageEvent<ElementEvent<any>>) => handleEvents(this, event);
  subscribeToEvents = () => subscribeToEvents(this);
  unsubscribeFromEvents = () => unsubscribeFromEvents(this);
  /**
   * This method pushes the options to the iframe by sending a message to the iframe and handles the response
   */
  pushOptions = async () => {
    const message_id = nanoid();
    const set_options_message: SetOptionsMessage<OutputElementOptions> = {
      type: 'setOptions',
      id: message_id,
      component_id: this.id,
      body: {
        id: this.id,
        type: this.type,
        class: this.class,
        css: this.css,
        secret_id: this.secret_id,
        placeholder: this.placeholder,
        google_font_url: this.google_font_url,
      },
    };
    const response = await sendMessage(this.html_element, set_options_message);
    return response;
  };

  /**
   * This method sends a message to the iframe to show the secret value in the output element
   */
  showSecretValue = async () => {
    const message_id = nanoid();
    const message: ShowSecretValueMessage = {
      id: message_id,
      component_id: this.id,
      type: 'showSecretValue',
    };
    const response = await sendMessage<'showSecretValue', ShowSecretValueResponse>(this.html_element, message);
    return response;
  };
}
