export class QueryStringUtils {
  /**
   * Stringify object to query string.
   * It also supports arrays and objects as values of the keys. They will be stringified
   * @example { value1: 'fsd fsd abc', value2: { a: 1 }, value3: 22, value4: 'SomeString', arr: [123] } -> value1=fsd%20fsd%20abc&value2=%7B%22a%22%3A1%7D&value3=22&value4=SomeString&arr=%5B123%5D
   */
  public static stringify(value: Record<string, any>, options?: { include_question_mark?: boolean }): string {
    if (!value || typeof value !== 'object') {
      return '';
    }

    const { include_question_mark = false } = options ?? {};

    const result_arr: string[] = [];
    for (const key in value) {
      result_arr.push(
        `${key}=${encodeURIComponent(typeof value[key] === 'object' ? JSON.stringify(value[key]) : value[key])}`
      );
    }
    return `${include_question_mark ? '?' : ''}${result_arr.join('&')}`;
  }

  /**
   * Parse query string params
   * @example "?value=abc&value2=dfg" -> { value: 'abc', value2: dfg }
   * @example "value=abc&value2=dfg" -> { value: 'abc', value2: dfg }
   */
  public static parse(value: any): undefined | Record<string, any> {
    if (value === undefined) {
      return undefined;
    }

    if (typeof value !== 'string') {
      throw new Error(`[QueryStringUtils] Value "${JSON.stringify(value)}" is not a string`);
    }

    const search_params = new URLSearchParams(value);
    if (!search_params) {
      return undefined;
    }

    const result: Record<string, any> = {};
    for (const [key, value] of Array.from(search_params.entries())) {
      result[key] = decodeURIComponent(value) || undefined;
    }

    return Object.keys(result).length > 0 ? result : undefined;
  }
}
