import { InputElementOptions, InputElementEvent } from '@external/shared';
import { InputElement as MInputElement } from '@external/mdb-js';
import { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { useModernbancContext } from './provider';

export type Props = Omit<InputElementOptions, 'type'> & {
  onReady?: (event: InputElementEvent<'ready'>) => void;
  onChange?: (event: InputElementEvent<'change'>) => void;
  onFocus?: (event: InputElementEvent<'focus'>) => void;
  onBlur?: (event: InputElementEvent<'blur'>) => void;
  onKeyDown?: (event: InputElementEvent<'key_down'>) => void;
} & {
  container?: {
    ref?: React.RefObject<HTMLDivElement>;
    style?: CSSProperties;
  };
};

export type InputElementRef = {
  focus: () => void;
  blur: () => void;
  id: string;
};

/**
 * Input element component in React to manage the Modernbanc input element
 */
export const InputElement = forwardRef(
  ({ onReady, onChange, onFocus, onBlur, onKeyDown, container, ...other_options }: Props, ref) => {
    const context = useModernbancContext();
    const [element, setElement] = useState<MInputElement>();

    useEffect(() => {
      const element = context.modernbanc.addElement({
        type: 'input',
        ...other_options,
        selectors: `#${other_options.id}`,
      });
      if (element) setElement(element);

      return () => {
        context.modernbanc.element_map.delete(element.id);
      };
    }, []);

    // when ref's methods are called, call the corresponding method on the element
    useEffect(() => {
      if (ref) {
        (ref as React.MutableRefObject<InputElementRef>).current = {
          focus: () => element?.focus(),
          blur: () => element?.blur(),
          id: other_options.id,
        };
      }
    }, [element]);

    useEffect(() => {
      element?.on('ready', onReady);
      element?.on('focus', onFocus);
      element?.on('blur', onBlur);
      element?.on('key_down', onKeyDown);
      element?.on('change', onChange);
    }, [element, onReady, onBlur, onChange, onFocus, onKeyDown]);

    useEffect(() => {
      element?.setOptions({ type: 'input', ...other_options });
    }, [JSON.stringify(other_options)]);

    return <div id={other_options.id} {...container} />;
  }
);
