import { generateElementUrl } from '../iframe.utils';
import { ElementEvent, InputElementOptions, OutputElementOptions } from '@external/shared';
import { InputElement } from './input.element';
import { OutputElement } from './output.element';

/**
 *
 * All of the functions below used to be in BaseElement - but inheritance bugs in javascript calls between super and sub functions were messing things up
 * So for now moved all these fns here.
 */

/**
 * This method constructs the iframe element and sets the options and attaches the iframe to element.html_element
 * API key must be present in that element by element point
 */
export function construct(element: InputElement | OutputElement, options: InputElementOptions | OutputElementOptions) {
  element.setOptions(options as any, false);
  element.html_element = document.createElement('iframe');
  element.html_element.width = '100%';
  element.html_element.height = '100%';
  element.html_element.style.background = 'none';
  element.html_element.style.border = 'none';
  element.html_element.style.overflow = 'visible';
  element.html_element.allow = 'clipboard-read; clipboard-write';
  element.html_element.src = generateElementUrl({ api_key: element.api_key });
  element.html_element.id = element.iframe_id;
  subscribeToEvents(element);
}

/**
 * This method sets the options for the element and calls pushOptions to push the options to the iframe
 */
export function setOptions(
  element: InputElement | OutputElement,
  options: InputElementOptions | OutputElementOptions,
  push = true
) {
  for (const key in options) {
    const value = (options as any)[key];
    Reflect.set(element, key, value);
  }
  if (options.id) {
    element.iframe_id = `iframe-${element.id}`;
  }
  if (push) {
    element.pushOptions();
  }
}

/**
 * This method subscribes to the events from the iframe
 */
export function subscribeToEvents(element: InputElement | OutputElement) {
  window.addEventListener('message', (event) => {
    handleEvents(element, event);
  });
}

/**
 * This method unsubscribes from the events from the iframe
 */
export function unsubscribeFromEvents(element: InputElement | OutputElement) {
  window.removeEventListener('message', (event) => {
    handleEvents(element, event);
  });
}

/**
 * This method handles the events from the iframe
 * Calls the registered event handler for the event type if it exists
 * or calls the pushOptions method if the event type is ready
 */
export async function handleEvents(element: InputElement | OutputElement, event: MessageEvent<ElementEvent<any>>) {
  if (!event.data) return;
  const element_event = event.data;
  if (element_event.component_id !== undefined && element_event.component_id !== element.id) return;
  if (!element_event.type || !element.allowed_event_types.includes(element_event.type)) return;
  const event_type = element_event.type;
  if (event_type === 'ready') {
    try {
      await element.pushOptions();
    } catch (err) {
      console.error('Error handling events when calling push options', err as any);
    }
  }
  element.registered_event_handlers.get(event_type)?.(element_event);
}
