export const elementTypes = ['input', 'output'] as const;
export type ElementType = typeof elementTypes[number];

export type Extends<T, U extends T> = U;

export type TInputHTMLType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export type BaseElementOptions = {
  type: ElementType;
  id: string;
  class?: string;
  css?: string;
  google_font_url?: string;
};
export type InputElementOptions = BaseElementOptions & {
  type: Extends<ElementType, 'input'>;
  placeholder?: string;
  disabled?: boolean;
  'aria-label'?: string;
  auto_complete?: string;
  value?: string;
  html_type?: TInputHTMLType;

  /** Deprecated - remove after customers migrated to react 0.0.10 */
  validation?: {
    regex?: RegExp;
  };

  /** */
  pattern?: string;
  max?: number | string;
  max_length?: number;
  min?: number | string;
  min_length?: number;
  replacers?: Replacer[];
  matchers?: Matcher[];

  deletion_date?: Date;
  variables?: Record<string, any> | null;
};

/**
 * Replacers allow you to replace the value in the input if it matches, behind the scenes we just pass through your values to JS's replace() function.
 * We offer some prebuilt replacer types like card_number or expiry_date which will add whitespaces/slashes etc.
 */
export type Replacer =
  | {
      type: 'regex';
      pattern: string;
      replacement: string;
    }
  | {
      type: 'card_number' | 'expiry_date';
    };

/**
 * Validators allow you to see if the user's entered final value matches a regex pattern.
 * Helpful for example to know if card is Visa (starts with 4, i.e /^4/ ) or Mastercard (starts with 5 i.e /^5/ )
 */
export type Matcher =
  | {
      type: 'regex';
      pattern: string;
      name: string;
    }
  | {
      type: 'valid_card_number';
    }
  | { type: 'valid_expiry_date' }
  | { type: 'card_type' };

export type OutputElementOptions = BaseElementOptions & {
  type: Extends<ElementType, 'output'>;
  secret_id: string;
  placeholder?: string;
};

export type AddBaseElementOptions = { selectors?: string };
export type AddInputElementOptions = AddBaseElementOptions & InputElementOptions;
export type AddOutputElementOptions = AddBaseElementOptions & OutputElementOptions;

export type ElementQueryParams = {
  api_key: string;
};
