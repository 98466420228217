import {
  CreateSecretMessage,
  ElementMessage,
  ElementMessageResponse,
  MessageType,
  SetOptionsResponse,
  ShowSecretValueResponse,
  TMessageMetadata,
  QueryStringUtils,
  ElementQueryParams,
} from '@external/shared';

export const element_ui_url = import.meta.env.VITE_ELEMENT_FRONTEND_URL;
if (!element_ui_url) throw Error("UI URL wasn't provided.");

const base_url = `${element_ui_url}`;

/**
 * Constructs the src url for the iframe element
 * @param params
 */
export function generateElementUrl(params: ElementQueryParams): string {
  const encoded_params = QueryStringUtils.stringify(params);
  const url = `${base_url}?${encoded_params}`;
  return url;
}

/**
 * Since browser's postMessage is one-directional without responses
 * we have to write a wrapper to simulate request-response like communication.
 */
export async function sendMessage<M extends MessageType, R extends ElementMessageResponse<M>>(
  element: HTMLIFrameElement,
  message: ElementMessage<M>
): Promise<R> {
  const promise = new Promise<R>((resolve, reject) => {
    function handleResponse(this: Window, event: MessageEvent<R>) {
      if (!event.data) {
        return reject(event.data);
      }

      const response: R = event.data;

      /* Check if response is not for us */
      const message_is_for_us = responseMatchesMessage(message as any as TMessageMetadata, response as any);
      if (!message_is_for_us) return;
      if (message.type !== response.type) return;

      if (response.is_error) {
        return resolve(response);
      }

      window.removeEventListener('message', handleResponse);
      resolve(response);
    }

    window.addEventListener('message', handleResponse);
    element.contentWindow?.postMessage(message, '*');
  });

  return promise;
}

/** When you have multiple iframes */
export function responseMatchesMessage(
  metadata: TMessageMetadata,
  response: SetOptionsResponse | ShowSecretValueResponse | CreateSecretMessage
) {
  /** Note: Ontop is not using this yet so need to avoid breaking changes.  */
  if (response.component_id === undefined || response.id === undefined) return true;
  if (metadata.id !== response.id) return false;
  if (metadata.component_id !== response.component_id) return false;
  return true;
}
